export const SET_CONSENTS = "consentsReducer/SET_CONSENTS";
export const SET_CONSENTS_ASSOCIATION_TYPE =
    "consentsReducer/SET_CONSENTS_ASSOCIATION_TYPE";

export enum ConsentStatusType {
    NotGranted = 0,
    Granted = 1,
    Rejected = 2,
}

export enum ConsentAreaType {
    Dooap,
    D365,
}

export enum ConsentAssociationType {
    Customer = 1,
    GuestTenant,
}

export interface IConsentStatus {
    type: ConsentStatusType;
    message: string | null;
}

export interface IConsentState {
    consentId: string;
    clientId: string;
    customerId: string;
    guestTenantId: string;
}

export interface IConsent {
    id: string;
    clientId: string;
    status: IConsentStatus;
    displayName: string;
    order: number;
    area: ConsentAreaType;
    environmentName: string;
    templatedName: string;
}

export interface IState {
    consents: IConsent[];
    consentAssociationType: ConsentAssociationType | null;
}

export interface ISetConsents {
    type: typeof SET_CONSENTS;
    consents: IConsent[];
}

export interface ISetConsentAssociationType {
    type: typeof SET_CONSENTS_ASSOCIATION_TYPE;
    consentAssociationType: ConsentAssociationType | null;
}

export type Actions = ISetConsents | ISetConsentAssociationType;
