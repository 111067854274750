import React from "react";
import { FlexboxGrid } from "rsuite";
import Bones from "../../assets/images/bones.svg";
import GotTheBoneDog from "../../assets/images/got-the-bone-dog.svg";
import BaseImage from "./BaseImage/BaseImage";
import BoneStepImage from "./BoneStepImage/BoneStepImage";
import DogImage from "./DogImage/DogImage";
import css from "./DogSteps.module.css";
import {
    IWizardStep,
    WizardStepStatusType,
} from "../../utils/types/wizard-types";

interface IComponentProps {
    wizardSteps: IWizardStep[];
    activeStepIndex: number;
    setActiveStepIndex: (index: number) => void;
}

const DogSteps: React.FC<IComponentProps> = ({
    wizardSteps,
    activeStepIndex,
    setActiveStepIndex,
}: IComponentProps) => {
    const isEveryStepGranted = wizardSteps.every(
        (step) => step.status === WizardStepStatusType.Resolved
    );
    const isSomeStepExist = wizardSteps.length > 0;
    const firstPendingStepIndex = wizardSteps.findIndex(
        (step) => step.status === WizardStepStatusType.Pending
    );
    const onBoneStepImageClickHandler = (step: IWizardStep, index: number) => {
        const isFirstPendingStep = index === firstPendingStepIndex;
        if (
            !(
                step.status === WizardStepStatusType.Pending &&
                !isFirstPendingStep
            )
        ) {
            setActiveStepIndex(index);
        }
    };
    return (
        <FlexboxGrid
            className={css.dogStepsContainer}
            justify="space-between"
            align={"bottom"}
        >
            <div></div>
            {/* If there are no steps at all - draw a dog at the beginning */}
            {!isSomeStepExist && <DogImage isConfused={false} />}
            {isSomeStepExist &&
                wizardSteps.map((step, index) => {
                    const isActiveStep = index === activeStepIndex;
                    return (
                        <div key={String(index)}>
                            <FlexboxGrid
                                align={"bottom"}
                                style={{ flexWrap: "nowrap" }}
                            >
                                {isActiveStep && !isEveryStepGranted && (
                                    <DogImage
                                        isConfused={
                                            step.status ===
                                            WizardStepStatusType.Failed
                                        }
                                    />
                                )}
                                <BoneStepImage
                                    step={step}
                                    index={index}
                                    isClickable={!isEveryStepGranted}
                                    handleClick={() => {
                                        onBoneStepImageClickHandler(
                                            step,
                                            index
                                        );
                                    }}
                                />
                            </FlexboxGrid>
                        </div>
                    );
                })}
            {isEveryStepGranted && isSomeStepExist ? (
                <BaseImage
                    imageSource={GotTheBoneDog}
                    style={{ bottom: "4px" }}
                    hasBackground
                    hasPadding
                />
            ) : (
                <BaseImage
                    imageSource={Bones}
                    hasBackground={true}
                    hasPadding={true}
                    style={{ bottom: "15px" }}
                />
            )}
            <div></div>
        </FlexboxGrid>
    );
};

export default DogSteps;
