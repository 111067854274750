import { template } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContainerProps, Tooltip, Whisper } from "rsuite";
import BoneGreen from "../../../assets/images/bone-green.svg";
import BoneRed from "../../../assets/images/bone-red.svg";
import Bone from "../../../assets/images/bone.svg";
import {
    IWizardStep,
    WizardStepStatusType,
} from "../../../utils/types/wizard-types";
import BaseImage from "../BaseImage/BaseImage";
import cn from "classnames";
import css from "./BoneStepImage.module.css";
import parse from "html-react-parser";

interface IComponentProps extends ContainerProps {
    step: IWizardStep;
    index: number;
    isClickable: boolean;
    handleClick: () => void;
}

const stepStatusToBoneImageMapping = {
    [WizardStepStatusType.Pending]: Bone,
    [WizardStepStatusType.Resolved]: BoneGreen,
    [WizardStepStatusType.Failed]: BoneRed,
};

const BoneStepImage: React.FC<IComponentProps> = ({
    step,
    index,
    isClickable,
    style,
    handleClick,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <Whisper
            trigger={"hover"}
            placement={"top"}
            speaker={
                <Tooltip className={css.tooltipContainer}>
                    <div>
                        {parse(
                            template(
                                t(
                                    "components.dogSteps.boneStepImage.tooltipHeaderTemplate"
                                )
                            )({
                                stepIndex: index + 1,
                                description: step.description,
                            })
                        )}
                    </div>
                </Tooltip>
            }
        >
            <div
                className={cn(css.boneStepImageContainer, {
                    [css.clickable]: isClickable,
                })}
            >
                <BaseImage
                    imageSource={stepStatusToBoneImageMapping[step.status]}
                    hasBackground={true}
                    hasPadding={true}
                    style={style}
                    onClick={() => {
                        if (isClickable) {
                            handleClick();
                        }
                    }}
                />
            </div>
        </Whisper>
    );
};

export default BoneStepImage;
