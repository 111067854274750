import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";
import consentsReducer from "./consents/consents-reducer";
import accountInfoReducer from "./account-info/account-info-reducer";
import customerReducer from "./customer/customer-reducer";
import loadingSpinnerReducer from "./loading-spinner/loading-spinner-reducer";
import errorsReducer from "./../redux/errors/errors-reduсer";
import guestTenantReducer from "./guest-tenants/guest-tenant-reducer";
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
    consentsReducer,
    accountInfoReducer,
    customerReducer,
    loadingSpinnerReducer,
    errorsReducer,
    guestTenantReducer,
});
const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export default store;
