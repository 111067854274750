import { IGuestTenantDisplay } from "../redux/account-info/account-info-types";
import { IConsent, IConsentStatus } from "../redux/consents/consents-types";
import {
    API,
    ResponseResult,
    getBadRequestResponseResult,
} from "../utils/api-config";

const baseAPIUri = "consent-management/guest-tenants";

export const getConsentsByGuestTenantId = async (
    guestTenantId: string
): Promise<ResponseResult<IConsent[]>> => {
    if (guestTenantId) {
        const response = await API.get(
            `${baseAPIUri}/${guestTenantId}/consents`
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<IConsent[]>([]);
};

export const getGuestTenantInfoByDirectoryId = async (
    directoryId: string,
    customerId: string,
    guestTenantId: string
): Promise<ResponseResult<IGuestTenantDisplay | null>> => {
    if (directoryId) {
        const response = await API.get(
            `${baseAPIUri}/${guestTenantId}/customer/${customerId}/directories/${directoryId}`
        );
        return { data: response.data || "", statusCode: response.status };
    }
    return getBadRequestResponseResult<IGuestTenantDisplay | null>(null);
};

export const updateGuestTenantDirectoryId = async (
    guestTenantId: string,
    directoryId: string,
    accountName: string
): Promise<ResponseResult<boolean | null>> => {
    if (guestTenantId && directoryId) {
        const response = await API.put(
            `${baseAPIUri}/${guestTenantId}/directories`,
            {
                id: directoryId,
                adminUserName: accountName,
            }
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<boolean | null>(false);
};

export const updateGuestTenantConsentStatus = async (
    guestTenantId: string,
    consentId: string,
    consentStatus: IConsentStatus
): Promise<ResponseResult<boolean>> => {
    if (guestTenantId && consentId) {
        const response = await API.put(
            `${baseAPIUri}/${guestTenantId}/consents/${consentId}/status`,
            consentStatus
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<boolean>(false);
};
