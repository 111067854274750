import axios, { AxiosHeaders } from "axios";
import { authProvider, loginRedirectRequest } from "./auth-provider";
import { responseStatusCodes } from "../utils/constants";

export type ResponseResult<T> = { data: T; statusCode: number };

export const getBadRequestResponseResult = <T>(
    defaultValue: T
): ResponseResult<T> => ({
    data: defaultValue,
    statusCode: responseStatusCodes.BadRequest,
});

export const API = axios.create({
    baseURL: `${String(process.env.REACT_APP_BASE_URI)}/api/`,
});

API.interceptors.request.use(
    async (config) => {
        await authProvider.initialize();
        const accounts = authProvider.getAllAccounts();
        const account = accounts[0] || null;
        try {
            const { accessToken } = await authProvider.acquireTokenSilent({
                account: account,
                scopes: loginRedirectRequest.scopes,
            });

            config.headers = new AxiosHeaders({
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
            });
        } catch {
            await authProvider.acquireTokenRedirect(loginRedirectRequest);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    async (response) => response,
    async (error) => await error.response
);
