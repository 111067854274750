import React, { useEffect } from "react";
import { routePath } from "../../utils/routes";
import { Navigate } from "react-router-dom";
import { ConsentAssociationType } from "../../redux/consents/consents-types";
import { uriQueryParameterNames } from "../../utils/constants";

interface IComponentProps {
    directoryCustomerId: string;
    consentCustomerId: string;
    consentGuestTenantId: string;
    setConsentCustomerId: (customerId: string) => void;
    setGuestTenantId: (guestTenantId: string) => void;
    setConsentAssociationType: (
        consentAssociationType: ConsentAssociationType
    ) => void;
}

const ControlAppRedirect: React.FC<IComponentProps> = ({
    directoryCustomerId,
    consentCustomerId,
    consentGuestTenantId,
    setConsentCustomerId,
    setGuestTenantId,
    setConsentAssociationType,
}: IComponentProps) => {
    const urlParams = new URLSearchParams(location.search);
    const customerIdParameterValue = urlParams.get(
        uriQueryParameterNames.customerId
    );
    const guestTenantIdParameterValue = urlParams.get(
        uriQueryParameterNames.guestTenantId
    );

    useEffect(() => {
        let consentAssociationType: ConsentAssociationType | null =
            directoryCustomerId || customerIdParameterValue
                ? ConsentAssociationType.Customer
                : null;
        consentAssociationType = guestTenantIdParameterValue
            ? ConsentAssociationType.GuestTenant
            : consentAssociationType;

        const newConsentCustomerId: string | null =
            directoryCustomerId || customerIdParameterValue;
        if (
            consentAssociationType === ConsentAssociationType.Customer &&
            !consentCustomerId &&
            newConsentCustomerId
        ) {
            setConsentCustomerId(newConsentCustomerId);
        } else if (
            consentAssociationType === ConsentAssociationType.GuestTenant &&
            !consentCustomerId &&
            customerIdParameterValue
        ) {
            setConsentCustomerId(customerIdParameterValue);
        }

        if (!consentGuestTenantId && guestTenantIdParameterValue) {
            setGuestTenantId(guestTenantIdParameterValue);
        }

        if (consentAssociationType) {
            setConsentAssociationType(consentAssociationType);
        }
    }, [
        customerIdParameterValue,
        guestTenantIdParameterValue,
        setConsentCustomerId,
        setGuestTenantId,
    ]);
    return <Navigate to={routePath.root} />;
};

export default ControlAppRedirect;
