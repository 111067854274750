import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routePath } from "../../utils/routes";
import ConsentResponseContainer from "../../pages/ConsentResponse/ConsentResponseContainer";
import ControlAppRedirectContainer from "../../pages/ControlAppRedirect/ControlAppRedirectContainer";
import InstructionsContainer from "../../pages/Instructions/InstructionsContainer";
import WizardContainer from "../../pages/Wizard/WizardContainer";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import CustomerQueryRouteContainer from "./CustomerQueryRoute/CustomerQueryRouteContainer";

interface IComponentProps {
    isAdministratorAccount: boolean;
}

const Router: React.FC<IComponentProps> = ({
    isAdministratorAccount,
}: IComponentProps) => (
    <Routes>
        <Route
            path={routePath.root}
            element={
                <CustomerQueryRouteContainer>
                    <InstructionsContainer />
                </CustomerQueryRouteContainer>
            }
        ></Route>
        <Route
            path={routePath.wizard}
            element={
                <ProtectedRoute isAllowed={isAdministratorAccount}>
                    <CustomerQueryRouteContainer>
                        <WizardContainer />
                    </CustomerQueryRouteContainer>
                </ProtectedRoute>
            }
        />
        <Route
            path={routePath.consentResponse}
            element={
                <ProtectedRoute isAllowed={isAdministratorAccount}>
                    <ConsentResponseContainer />
                </ProtectedRoute>
            }
        />
        <Route
            path={routePath.controlAppRedirect}
            Component={ControlAppRedirectContainer}
        />
        <Route path="*" element={<Navigate to={routePath.root} replace />} />
    </Routes>
);

export default Router;
