import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { authProvider } from "./utils/auth-provider";
import AuthenticationContainer from "./components/Authentication/AuthenticationContainer";
import LayoutContainer from "./components/Layout/LayoutContainer";
import RouterContainer from "./components/Router/RouterContainer";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./utils/i18n";
import "./themes/styles.less";

document.title = `${process.env.REACT_APP_ENV_NAME} Consent Wizard`;

const root = createRoot(document.getElementById("root")!);

root.render(
    <Provider store={store}>
        <MsalProvider instance={authProvider}>
            <AuthenticationContainer>
                <BrowserRouter>
                    <LayoutContainer>
                        <RouterContainer />
                    </LayoutContainer>
                </BrowserRouter>
            </AuthenticationContainer>
        </MsalProvider>
    </Provider>
);
