import React from "react";
import { useTranslation } from "react-i18next";
import { FlexboxGrid, Message } from "rsuite";
import css from "./ErrorBoundary.module.css";
interface IErrorBoundaryProps {
    errorMessage: string;
}

const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({
    errorMessage,
}: IErrorBoundaryProps) => {
    const { t } = useTranslation();
    return (
        <div className={css.errorBoundaryContainer}>
            <FlexboxGrid
                className={css.errorBoundaryBody}
                justify="space-around"
            >
                <FlexboxGrid.Item colspan={21}>
                    <h1>{t("components.errorBoundary.header")}</h1>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={21}>
                    <Message
                        showIcon
                        type="error"
                        title={t("components.errorBoundary.errorMessageTitle")}
                    >
                        {errorMessage}
                    </Message>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={21}>
                    <Message
                        showIcon
                        type="info"
                        title={t("components.errorBoundary.infoMessageTitle")}
                    >
                        {t("components.errorBoundary.infoMessage")}
                    </Message>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};

export default ErrorBoundary;
