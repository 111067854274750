import {
    API,
    ResponseResult,
    getBadRequestResponseResult,
} from "../utils/api-config";
import { IConsent, IConsentStatus } from "../redux/consents/consents-types";
import { CustomerType } from "../redux/customer/customer-types";
import { IEntityDisplay } from "../redux/account-info/account-info-types";

const baseAPIUri = "consent-management";

export const getConsentsByCustomerId = async (
    customerId: string
): Promise<ResponseResult<IConsent[]>> => {
    if (customerId) {
        const response = await API.get(
            `${baseAPIUri}/customers/${customerId}/consents`
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<IConsent[]>([]);
};

export const updateCustomerConsentStatus = async (
    customerId: string,
    consentId: string,
    consentStatus: IConsentStatus
): Promise<ResponseResult<boolean>> => {
    if (customerId && consentId) {
        const response = await API.put(
            `${baseAPIUri}/customers/${customerId}/consents/${consentId}/status`,
            consentStatus
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<boolean>(false);
};

export const getCustomerTypeByCustomerId = async (
    customerId: string
): Promise<ResponseResult<CustomerType | null>> => {
    if (customerId) {
        const response = await API.get(
            `${baseAPIUri}/customers/${customerId}/type`
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<CustomerType | null>(null);
};

export const updateCustomerDirectoryId = async (
    customerId: string,
    directoryId: string,
    accountName: string
): Promise<ResponseResult<boolean>> => {
    if (customerId && directoryId) {
        const response = await API.put(
            `${baseAPIUri}/customers/${customerId}/directories`,
            {
                id: directoryId,
                adminUserName: accountName,
            }
        );
        return { data: response.data, statusCode: response.status };
    }
    return getBadRequestResponseResult<boolean>(false);
};

export const getCustomerInfoByDirectoryId = async (
    directoryId: string
): Promise<ResponseResult<IEntityDisplay | null>> => {
    if (directoryId) {
        const response = await API.get(
            `${baseAPIUri}/customers/id/directories/${directoryId}`
        );
        return { data: response?.data || "", statusCode: response.status };
    }
    return getBadRequestResponseResult<IEntityDisplay | null>(null);
};

export const getDirectoryIdByCustomerId = async (
    customerId: string
): Promise<ResponseResult<string | null>> => {
    if (customerId) {
        const response = await API.get(
            `${baseAPIUri}/customers/${customerId}/directories/id`
        );
        return { data: response.data || null, statusCode: response.status };
    }
    return getBadRequestResponseResult<string | null>(null);
};
