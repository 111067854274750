import React from "react";
import { Navigate } from "react-router-dom";
import { routePath } from "../../../utils/routes";

interface IComponentProps {
    isAllowed: boolean;
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<IComponentProps> = ({
    isAllowed,
    children,
}: IComponentProps) => {
    return isAllowed ? <>{children}</> : <Navigate to={routePath.root} />;
};

export default ProtectedRoute;
